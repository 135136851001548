<template>
  <div>
    <sdPageHeader :title="PurchaseId ? 'Edit Order to supllier' : 'Add new Order to supllier'">
      <template v-slot:buttons>
        <div class="page-header-actions">
          <sdButton v-if="PurchaseId && $userHasPermission('Permissions.Purchase.Update')" @click="handleChangeReadMode"
            size="small" type="primary" style="gap: 5px">
            {{ readOnlyMode ? "Edit" : "Read only" }}
            <sdFeatherIcons :type="readOnlyMode ? 'edit' : 'eye'" size="14" />
          </sdButton>
          <div class="export-buttons" v-if="isPdfGenerated">
            <sdButton v-if="isPdfGenerated && !mailIsLoading" type="white" size="small" @click="sendPurchaseViaMail()"
              style="gap: 5px">
              Send via mail
              <sdFeatherIcons type="send" />
            </sdButton>
            <div v-if="mailIsLoading" style="margin-left: 8px">
              <a-spin />
            </div>
          </div>
          <div v-if="pdfIsLoading" style="margin-left: 8px">
            <a-spin />
          </div>
          <sdPopover v-else placement="bottomLeft" action="click">
            <template v-slot:content>
              <a @click="generatePdf(1)" style="gap: 5px">
                <sdFeatherIcons type="download" />
                Export PDF
              </a>
              <a v-if="isPdfGenerated" size="small" type="secondary" @click="generatePdf(2)" style="gap: 5px">
                <sdFeatherIcons type="search" />
                Visualize PDF
              </a>
            </template>
            <sdButton size="small" type="white">
              <span>Export</span>
              <sdFeatherIcons type="download" />
            </sdButton>
          </sdPopover>
          <sdButton size="small" type="white" @click="toggelHistory()">
            <span>{{ $t('history') }}</span>
            <sdFeatherIcons type="clock" />
          </sdButton>
        </div>
      </template>
    </sdPageHeader>

    <Main>
      <a-form class="main-form" name="sDash_validation-form" ref="formRef" :rules="rules" @finish="onSubmit"
        :model="formState" :layout="formState.layout">
        <a-row :gutter="25">
          <a-col :xs="14">
            <sdCards :title="'Order details'">
              <FormValidationWrap>
                <HorizontalFormStyleWrap>
                  <a-row :gutter="30">
                    <a-col :span="8" :xs="8">
                      <a-form-item name="date" label="Date">
                        <a-date-picker :disabled="PurchaseApiLoading || readOnlyMode" v-model:value="formState.date"
                          :style="{ width: '100%' }" :format="dateFormat" />
                      </a-form-item>
                    </a-col>
                    <a-col :span="8" :xs="8">
                      <a-form-item label="Partner" name="partnerId">
                        <a-select v-if="partnerList?.length" :disabled="DealId || PurchaseId || readOnlyMode"
                          v-model:value="formState.partnerId" placeholder="Please choose partner" show-search
                          style="width: 100%" :options="partnerData" :not-found-content="partnerFetching ? undefined : null
                            " :filter-option="false" @search="searchPartner">
                          <template v-if="partnerFetching" #notFoundContent>
                            <a-spin size="small" />
                          </template>
                        </a-select>
                        <a-select v-else :disabled="DealId || PurchaseId || readOnlyMode"
                          v-model:value="formState.partnerId" placeholder="Please choose partner">
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :span="8" :xs="8">
                      <a-form-item name="number" label="Number">
                        <a-input class="custom" :disabled="PurchaseApiLoading || readOnlyMode"
                          v-model:value="formState.number" :style="{ width: '100%' }" />
                      </a-form-item>
                    </a-col>
                    <a-col :span="8" :xs="8">
                      <a-form-item label="VAT" name="saleStatus">
                        <a-input-number :disabled="readOnlyMode" :style="{ width: '100%' }" :min="0" :max="100"
                          :formatter="(value) => `${value}%`" :parser="(value) => value.replace('%', '')"
                          v-model:value="formState.vat" placeholder="Value" />
                      </a-form-item>
                    </a-col>
                    <a-col :span="8" :xs="8">
                      <a-form-item label="Status" name="purchaseStatus">
                        <a-select v-model:value="formState.purchaseStatus" :disabled="readOnlyMode">
                          <a-select-option :value="1"> Draft</a-select-option>
                          <a-select-option :value="2">
                            Confirmed</a-select-option>
                          <a-select-option :value="3">
                            Canceled</a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :span="8" :xs="8">
                      <a-form-item name="followedById" label="Followed by">
                        <a-select v-model:value="formState.followedById" :field-names="{ label: 'fullName', value: 'id', }"
                          :filter-option="filterContact" placeholder="Select a person"> <a-select-option
                            v-for="(contact, index) in contactApi" :key="index" :value="contact.id">
                            {{ contact.fullName }}</a-select-option></a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :xs="12" :md="12">
                      <a-form-item label="Note">
                        <a-textarea v-model:value="formState.note" :disabled="readOnlyMode" :style="{ width: '100%' }"
                          placeholder="Note ..." name="address" class="sDash_unresizable" />
                      </a-form-item>
                    </a-col>
                    <a-col :xs="12" :md="12">
                      <a-form-item label="Private Note">
                        <a-textarea v-model:value="formState.privateNote" :disabled="readOnlyMode"
                          :style="{ width: '100%' }" placeholder="Private Note ..." name="address"
                          class="sDash_unresizable" />
                      </a-form-item>
                    </a-col>
                    <a-col :span="6" :offset="18" class="button-container" v-if="!readOnlyMode">
                      <sdButton htmlType="onSubmit" key="onSubmit" class="btn-outlined ant-btn-lg client-form-submit"
                        outlined type="primary">
                        <span>Save</span>
                      </sdButton>
                    </a-col>
                  </a-row>
                </HorizontalFormStyleWrap>
              </FormValidationWrap>
            </sdCards>
          </a-col>
          <a-col class="w-100" :md="10">
            <sdCards title="Payment Detail">
              <FormValidationWrap>
                <HorizontalFormStyleWrap>
                  <a-form-item label="Payment Method" name="paymentMethodId">
                    <a-select v-model:value="formState.paymentMethodId" :disabled="readOnlyMode"
                      placeholder="Select a payment methode">
                      <a-select-option v-for="(payment, index) in PaymentMethodApi" :key="index" :value="payment.id">
                        {{ payment.name }}</a-select-option>
                    </a-select>
                  </a-form-item>
                  <a-form ref="formRefPayment" name="dynamic_form_nest_item" :model="dynamicValidateForm"
                    @finish="onFinish" :layout="formState.layout">
                    <a-space v-for="(payment, index) in PurchasePaymentTermsApi" :key="payment.id">
                      <a-row  class="payments-row">
                          <a-col :span="24
                            " v-if="totalTtc > 0 && !isNaN(payment.value)" class="payment-percent-value"><span
                                    :title="totalTtc * (payment.value / 100)">{{ totalTtc * (payment.value / 100)
                                    }}</span></a-col>
                        </a-row>
                      <a-row justify="space-between">
                        <a-col :span="6">
                          <a-form-item :name="['payments', index, 'value']" :rules="{
                            required: true,
                            message: 'Missing Modality value',
                          }">
                            <a-input-number :disabled="readOnlyMode" :style="{ width: '100%' }" :min="0" :max="100"
                              :formatter="(value) => `${value}%`" :parser="(value) => value.replace('%', '')"
                              v-model:value="payment.value" placeholder="Value" /> </a-form-item></a-col>
                        
                        <a-col :span="6">
                          <a-form-item :name="['payments', index, 'numberOfDays']">
                            <a-input-number :disabled="readOnlyMode" :style="{ width: '100%' }" :min="0"
                              v-model:value="payment.numberOfDays" placeholder="Days" /> </a-form-item>
                        </a-col>

                        <a-col :span="8">
                          <a-form-item :name="['payments', index, 'paymentTermOptionId']">
                            <a-select v-if="PaymentTermOptionApi.length" :disabled="readOnlyMode"
                              placeholder="Payment Term option" v-model:value="payment.paymentTermOptionId">
                              <a-select-option v-for="(paymentTerm, index) in PaymentTermOptionApi" :key="index"
                                :value="paymentTerm.id">
                                {{ paymentTerm.name }}</a-select-option>
                            </a-select>
                            <a-select v-else :disabled="readOnlyMode" placeholder="Payment Term option"></a-select>
                          </a-form-item></a-col>
                        <a-col :span="3" class="payment-buttons" v-if="!readOnlyMode">
                          <sdFeatherIcons @click="addModality(payment)" type="check-circle" />
                          <sdFeatherIcons @click="removePayment(payment)" type="minus-circle" />
                        </a-col>
                        
                      </a-row>
                      
                    </a-space>
                    <a-form-item>
                      <a-button type="dashed" block @click="addPayment" v-if="!readOnlyMode">
                        <PlusOutlined />
                        Add payment
                      </a-button>
                    </a-form-item>
                  </a-form>
                </HorizontalFormStyleWrap>
              </FormValidationWrap>
            </sdCards>
          </a-col>
        </a-row>
      </a-form>
      <a-row :gutter="25">
        <a-col :xs="24">
          <sdCards title="Products" caption="Products">
            <template #button>
              <div class="card-nav">
                <ul>
                  <li>
                    <sdButton v-if="!readOnlyMode" @click="showAddProductModal" size="small" type="primary"
                      style="gap: 5px">
                      Add Products
                      <sdFeatherIcons type="plus" size="14" />
                    </sdButton>
                  </li>
                </ul>
              </div>
            </template>
            <a-table :columns="columns" :dataSource="productsData" :loading="isPurchaseProductLoading" bordered>
              <template #price="{ record }">
                <div>{{ record.price }} {{ record.currency }}</div>
              </template>
              <template #total="{ record }">
                <div>
                  {{ record.quantity * record.price }} {{ record.currency }}
                </div>
              </template>
              <template #operation="{ record }">
                <div class="editable-row-operations">
                  <span>
                    <a v-if="!readOnlyMode">
                      <sdFeatherIcons @click="showEditProductModal(record)" type="edit" />
                    </a>
                    <a v-if="!readOnlyMode" @click="deleteFromPurchase(record)">
                      <sdFeatherIcons type="trash" />
                    </a>
                  </span>
                </div>
              </template>
              <template #footer>
                <div class="table-footer">
                  Total NET : {{ totalNet }}
                </div>
                <div class="table-footer">
                  Total VAT : {{ totalVat.toFixed(2) }}
                </div>
                <div class="table-footer">
                  Total TTC : {{ totalTtc }}
                </div>
              </template>
            </a-table>
          </sdCards>
        </a-col>
      </a-row>
      <a-row :gutter="25">
        <a-col :xs="24">
          <AuxiliaryCharge parentType="2" ref="auxiliaryChargeCmp" :readOnlyMode="readOnlyMode" :parentId="PurchaseId">
          </AuxiliaryCharge>
        </a-col>
      </a-row>
    </Main>
    <AddProductModal :dealId="DealId" :partnerId="formState.partnerId" :productsIds="productsIds"
      @hideAddProductModal="hideAddProductModal" @SelectedProduct="AddSelectedProduct" v-if="showAddProduct">
    </AddProductModal>
    <HistoryModal v-if="showHistoryModal" entity="Purchase" :entityId="PurchaseId" @Cancel="toggelHistory">
    </HistoryModal>
    <EditProductModal :product="currentProduct" :productId="EditProductId" :purchaseId="PurchaseId"
      @hideEditProductModal="hideEditProductModal" @updateProduct="updateProduct"
      v-if="showEditProduct && currentProduct"></EditProductModal>
  </div>
</template>

<script>
import {
  FormValidationWrap,
  HorizontalFormStyleWrap,
} from "../forms/overview/Style";
import { RecordViewWrapper } from "../../view/crud/axios/style";
import { Main, TableWrapper } from "../styled";
import { useStore } from "vuex";
import { useProduct } from "@/composable/useProduct";
import {
  MinusCircleOutlined,
  CheckCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";

import { notification, Modal } from "ant-design-vue";
import { DataService } from "@/config/dataService/dataService";

import {
  computed,
  onMounted,
  reactive,
  ref,
  watchEffect,
  nextTick,
  defineAsyncComponent,
} from "vue";
import { useRoute, onBeforeRouteLeave } from "vue-router";
import moment from "moment";
import usePartnerSearch from "../../composable/usePartnerSearch";

const AddProductModal = defineAsyncComponent(() =>
  import("@/view/product/AddProductModal")
);
const EditProductModal = defineAsyncComponent(() =>
  import("@/view/product/EditProductModal")
);
const AuxiliaryCharge = defineAsyncComponent(() => import("@/components/businessComponents/AuxiliaryCharge.vue"));
const HistoryModal = defineAsyncComponent(() => import("@/components/businessComponents/HistoryModal.vue"));

const dateFormat = "YYYY/MM/DD";
const todayDate = moment();

const columns = [
  {
    title: "Description",
    dataIndex: "description",
    width: "20%",
  },
  {
    title: "Condition",
    dataIndex: "conditionName",
    width: "15%",
  },
  {
    title: "Unit Price",
    dataIndex: "price",
    width: "20%",
    slots: {
      customRender: "price",
    },
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    width: "10%",
  },
  {
    title: "Total Price",
    dataIndex: "total",
    width: "15%",

    slots: {
      customRender: "total",
    },
  },
  {
    title: "Operation",
    dataIndex: "operation",
    width: "15%",
    slots: {
      customRender: "operation",
    },
  },
];
const data = [];

const Purchase = {
  name: "Purchase",
  components: {
    RecordViewWrapper,
    Main,
    TableWrapper,
    FormValidationWrap,
    HorizontalFormStyleWrap,
    AddProductModal,
    EditProductModal,
    AuxiliaryCharge,
    HistoryModal,
    MinusCircleOutlined,
    PlusOutlined,
    CheckCircleOutlined,
  },
  setup() {
    const { state, dispatch } = useStore();
    const { formatProductToList } = useProduct();
    const { params } = useRoute();
    const PaymentTermOptionApi = computed(() => state.PaymentTermOptionApi.data);
    const partnerApi = computed(() => state.partnerApi.data);
    const partnerApiLoading = computed(() => state.partnerApi.loading);
    const PurchaseApi = computed(() => state.purchaseApi);
    const PaymentMethodApi = computed(() => state.PaymentMethodApi.data);
    const PurchasePaymentTermsApi = computed(
      () => state.PurchasePaymentTermsApi.data
    );
    const auxiliaryChargeCmp = ref(null);
    const auxiliaryTotalCharge = computed(() => {
      return auxiliaryChargeCmp.value?.totalCharge;
    });
    const purchaseNumber = computed(() => state.purchaseApi.purchaseNumber);
    const PurchaseApiLoading = computed(() => state.purchaseApi.loading);
    const isPurchaseProductLoading = computed(
      () => state.productPurchaseApi.loading
    );
    const contactApi = computed(() => state.contactApi.data);
    const productPurchaseApi = computed(() => state.productPurchaseApi.data);
    const selectedProducts = ref(data);
    let DealId = ref("");
    let isPdfGenerated = ref(false);
    let pdfIsLoading = ref(false);
    let mailIsLoading = ref(false);
    let DealIsPartialSale = ref(true);
    let productCountInDeal = ref(0);
    const editableData = reactive({});

    var currentPurchaseNumber = ref("");
    var formState = reactive({
      layout: "vertical",
      partnerId: null,
      followedById: null,
      paymentMethodId: null,
      number: null,
      purchaseType: 1,
      purchaseStatus: 1,
      vat: 0,
      date: todayDate,
      note: "",
      privateNote: "",
    });

    const formRef = ref();
    //const showAddProduct = ref(false);
    //read only
    var readOnlyMode = ref(true);

    const handleChangeReadMode = () => {
      return readOnlyMode.value = !readOnlyMode.value
    };

    const rules = {
      date: [
        {
          type: "object",
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
      number: [
        {
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
      partnerId: [
        {
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
    };
    let purchaseProductLength = computed(
      () =>
        productPurchaseApi.value &&
        productPurchaseApi.value.length &&
        productPurchaseApi.value.length
    );

    onMounted(() => {
      dispatch("partnerInfo");
      dispatch("contactInfo");
      dispatch("paymentMethodInfo");
      dispatch("emptyPurchase");
      dispatch("paymentTermOptionGetAllData");

      nextTick();
      if (params.id) {
        dispatch("singlePurchaseGetData", params.id);
        dispatch("purchasePaymentTermsGetData", params.id);
        dispatch("auxiliaryChargeGetData", `PurchaseId=${params.id}`);
        
        PurchaseId.value = params.id;
      } else {
        readOnlyMode.value = false;

        const generateData = {
          purchaseType: 1,
          Year: moment().year(),
        };
        dispatch("purchaseGenerateNumber", generateData);
      }

      if (productPurchaseApi.value && productPurchaseApi.value.length) {
        formState.partnerId = productPurchaseApi.value[0].partnerId;
        partnerData.value.push({
          value: productPurchaseApi.value[0].partnerId,
          label: productPurchaseApi.value[0].partenaireName,
        });
        productCountInDeal.value =
          productPurchaseApi.value[0].productCountInDeal;
        DealIsPartialSale.value =
          productPurchaseApi.value[0].dealIsPartialSale == 2;
        DealId.value = productPurchaseApi.value[0].dealId;
        if (!PurchaseId.value) {
          dispatch("auxiliaryChargeGetFromDeal", { target: `DealId=${DealId.value}`, parentType: 2 });
        }
      }
    });

    const productsData = computed(() => {
      if (productPurchaseApi.value && productPurchaseApi.value.length) {
        // Mapping logic for the first condition
        return productPurchaseApi.value.map((product, key) => {
          const {
            productId,
            countryName,
            unitPrice,
            purchaseId,
            currency,
            isPartialSale,
            condition,
            pslId,
            drift,
            conditionName,
            quantityVirtuelle,
            quantityInStock,
            description,
            quantityInitial,
            unitOfMeasureId,
            unitOfMeasureName,
            deliveryTime,
            productType,
            numberPackage,
            totalPrice,
            availability,
            currencyId,
            currencyName,
            incotermId,
            incotermName,
            stockLocation,
            stockLocationMaps,
            countryId,
            originCountryId,
            originCountryName,
            categoryId,
            categoryName,
            sizeId,
            sizeName,
            gradeId,
            gradeName,
            weightId,
            weightName,
            rangeId,
            rangeName,
            threadId,
            threadName,
            hsCodeId,
            hsCodeName,
            note,
            quantity,
          } = product;

          return {
            key: key + 1,
            id: productId,
            productId: productId,
            description,
            condition: condition,
            country: countryName,
            purchaseId: purchaseId,
            currency,
            conditionName,
            isPartialSale,
            priceAndCurrency: `${unitPrice} ${currency}`,
            price: product.price ?? unitPrice,
            quantity: quantity,
            quantityVirtuelle,
            quantityInStock,
            quantityInitial,
            unitOfMeasureId,
            unitOfMeasureName,
            deliveryTime,
            productType,
            numberPackage,
            totalPrice,
            availability,
            currencyId,
            currencyName,
            incotermId,
            incotermName,
            stockLocation,
            stockLocationMaps,
            countryId,
            originCountryId,
            originCountryName,
            categoryId,
            categoryName,
            sizeId,
            sizeName,
            gradeId,
            gradeName,
            weightId,
            weightName,
            rangeId,
            rangeName,
            threadId,
            threadName,
            hsCodeId,
            hsCodeName,
            note,
            pslId,
            drift,
          };
        });
      } else {
        return [];
      }
    });

    const productsIds = computed(() =>
      productPurchaseApi.value && productPurchaseApi.value.length
        ? productPurchaseApi.value.map((product) => product.id)
        : []
    );

    const partnerList = computed(() => {
      return partnerApi.value;
    });
    const selectedPartner = computed(() =>
      partnerApi.value?.length && formState.partnerId ?
        partnerApi.value.find(element => element.id == formState.partnerId) : {}
    );

    const canExportPdf = computed(() =>
      selectedPartner.value ?
        selectedPartner.value.taxRegistration || selectedPartner.value.numeroRegistreCommerce : false
    );

    const PurchaseId = computed(() => {
      if (PurchaseApi.value) {
        return PurchaseApi.value.purchaseId;
      }
      return null;
    });

    const onSubmit = () => {
      formRef.value
        .validate()
        .then(async () => {
          formState.totalNet = totalCost;
          if (!PurchaseId.value) {
            formState.products = productsData.value;
            dispatch("purchaseSubmitData", { formState });
          } else {
            delete formState.products;
            dispatch("purchaseUpdateData", { formState });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    const fillAllProducts = (dealId, partnerId = "") => {
      if (!DealId.value) {
        DealId.value = dealId;
      }
      if (!formState.partnerId) {
        formState.partnerId = partnerId;
      }
      dispatch("addAllDealProductsToPurchase", dealId);
      hideAddProductModal(false);
    };
    const totalCost = ref(0);
    const calculateTotalCost = () => {
      let totalCostSum = 0;
      for (let i = 0; i < productsData.value.length; i++) {
        const product = productsData.value[i];
        const quantity = product.quantity;
        const unitPrice = product.price ?? product.unitPrice;

        totalCostSum += quantity * unitPrice;
      }

      totalCost.value = totalCostSum;
    };

    const deleteFromPurchase = (product) => {
      const confirm = window.confirm("Are you sure delete this?");
      if (confirm) {
        if (PurchaseId.value) {
          product.purchaseId = PurchaseId.value;
          product.productId = product.id;
          dispatch("purchaseProductApiDataDelete", product);
        } else {
          dispatch("purchaseProductDataDelete", product.productId);
        }
      }
      return false;
    };

    // Deal realted logic
    // #region Edit Product
    const EditProductId = ref(null);
    var currentProduct = reactive({});

    const showEditProduct = ref(false);

    const showEditProductModal = (product) => {
      Object.assign(currentProduct, product);
      EditProductId.value = product.productId;
      showEditProduct.value = true;
    };
    const hideEditProductModal = (value) => {
      showEditProduct.value = value;
      currentProduct.value = {};
    };
    const updateProduct = (formState) => {
      if (PurchaseId.value) {
        dispatch("productPurchaseApiUpdateData", formState);
      } else {
        dispatch("productPurchaseSimpleUpdateData", formState);
      }
      showEditProduct.value = false;
    };
    // #region Product
    const showAddProduct = ref(false);

    const showAddProductModal = () => {
      showAddProduct.value = true;
    };
    const hideAddProductModal = (value) => {
      showAddProduct.value = value;
    };
    const AddSelectedProduct = (product) => {
      if (PurchaseId.value) {
        product.productId = product.id;
        product.purchaseId = PurchaseId.value;
        dispatch("purchaseProductSubmitData", product);
      } else {
        if (!DealId.value) {
          DealId.value = product.dealId;
        }
        if (!formState.partnerId) {
          formState.partnerId = product.partnerId;
        }
        productPurchaseApi.value.push(formatProductToList(product));
      }

      if (product.deal.partialSale == 2 && productCountInDeal.value - 1 == 0) {
        DealIsPartialSale.value = true;

        if (PurchaseId.value) {
          product.productId = product.id;
          product.purchaseId = PurchaseId.value;
          dispatch("purchaseProductSubmitData", product);
        } else {
          if (
            !DealIsPartialSale.value &&
            purchaseProductLength.value + 1 != productCountInDeal.value
          ) {
            Modal.warning({
              title: "Partial Sale is not possible for this Quotation. ",
              content:
                "You need to add all products from this quotation to proceed with your order.",
              okText: "Add all quotation products",
              closable: true,
              onOk() {
                fillAllProducts(product.dealId, product.partnerId);
              },
              cancelText: "I'am aware",
              onCancel() {
                productPurchaseApi.value.push(formatProductToList(product));
              },
            });
          } else {
            productPurchaseApi.value.push(formatProductToList(product));
          }
        }
      } else {
        DealIsPartialSale.value = product.deal.partialSale == 2;
      }
    };
    // #endegion

    /// #region  Payment method methode
    const formRefPayment = ref();
    const dynamicValidateForm = reactive({
      payments: [],
    });
    const removePayment = (item) => {
      if (item.id) {
        dispatch("purchasePaymentTermsApiDataDelete", item);
      } else {
        let index = PurchasePaymentTermsApi.value.findIndex(
          (element) => element.index === item.index
        );
        if (index !== -1) {
          PurchasePaymentTermsApi.value.splice(index, 1);
        }
      }
    };
    const addPayment = () => {
      formRefPayment.value
        .validate()
        .then(async () => {
          PurchasePaymentTermsApi.value.push({
            purchaseId: PurchaseId.value,
            parentType: 1,
            index: PurchasePaymentTermsApi.value.length,
            id: undefined,
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    const addModality = (item) => {
      formRefPayment.value
        .validate()
        .then(async () => {
          if (item.id) {
            dispatch("purchasePaymentTermsUpdateData", item);
          } else {
            dispatch("purchasePaymentTermsSubmitData", item);
          }
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          onSubmit();
        });
    };

    dynamicValidateForm.payments = computed(() =>
      PurchasePaymentTermsApi.value && PurchasePaymentTermsApi.value.length
        ? PurchasePaymentTermsApi.value.map((paymentTerm, key) => {
          return {
            key: key + 1,
            id: paymentTerm.id,
            value: paymentTerm.value,
            detail: paymentTerm.detail,
          };
        })
        : []
    );

    // #endregion
    onBeforeRouteLeave(() => {
      dispatch("emptyProductPurchaseData");
      dispatch("emptyPurchase");
      dispatch("emptyPaymentTermsPurchaseData");
      formState.partnerId = "";
    });

    /// watch
    watchEffect(() => {
      if (
        PurchaseApi.value &&
        PurchaseApi.value.purchaseId &&
        partnerApi.value &&
        partnerApi.value.length
      ) {
        console.log("here", PurchaseApi.value);
        formState.id = PurchaseApi.value.apiResponse.id;
        formState.date = moment(PurchaseApi.value.apiResponse.date);
        formState.number = PurchaseApi.value.apiResponse.number;
        formState.vat = PurchaseApi.value.apiResponse.vat;
        formState.purchaseStatus = PurchaseApi.value.apiResponse.purchaseStatus;
        isPdfGenerated.value = PurchaseApi.value.apiResponse.isPdfGenerated;
        formState.partnerId = PurchaseApi.value.apiResponse.partnerId;
        formState.followedById = PurchaseApi.value.apiResponse.followedById;
        formState.paymentMethodId = PurchaseApi.value.apiResponse.paymentMethodId;
        formState.note = PurchaseApi.value.apiResponse.note;
        formState.privateNote = PurchaseApi.value.apiResponse.privateNote;
        if (partnerData.value.length < 1) {
          partnerData.value.push({
            value: PurchaseApi.value.apiResponse.partnerId,
            label: PurchaseApi.value.apiResponse.partnerName,
          });
        }
      }

      if (productPurchaseApi.value && productPurchaseApi.value.length) {
        productCountInDeal.value =
          productPurchaseApi.value[0].productCountInDeal;
        DealIsPartialSale.value =
          productPurchaseApi.value[0].dealIsPartialSale == 2;
        DealId.value = productPurchaseApi.value[0].dealId;

        calculateTotalCost();
      }

      if (purchaseNumber.value) {
        formState.number = purchaseNumber.value;
      }
    });

    const generatePdf = async (type) => {
      try {
        var response;
        pdfIsLoading.value = true;
        if (type == 1) {
          if (!canExportPdf.value) {
            notification.error({
              message: "To export a PDF, it is necessary for the partner to possess either a Commerce Registration or a Tax Registration",
            });
            return;
          }
          response = await DataService.getFile(
            "api/Purchase/GeneratePDF?PurchaseId=" + PurchaseId.value
          );
          isPdfGenerated.value = true;
        } else {
          response = await DataService.getFile(
            "api/Purchase/GetLastGeneratedPdf?PurchaseId=" + PurchaseId.value
          );
        }

        if (response) {
          var byteCharacters = response;
          var blob = new Blob([byteCharacters], { type: "application/pdf" });
          var url = URL.createObjectURL(blob);
          window.open(url, "_blank");
        }
      } catch (err) {
        notification.error({
          message: "Error while downloading PDF",
        });
      } finally {
        pdfIsLoading.value = false;
      }
    };

    const sendPurchaseViaMail = async () => {
      try {
        var response;
        mailIsLoading.value = true;
        response = await DataService.get(
          "api/Purchase/SendPdfViaEmail?purchaseId=" + PurchaseId.value
        );

        if (response) {
          if (response.succeeded)
            notification.success({
              message: response.message,
            });
        } else {
          notification.error({
            message: response.message,
          });
        }
      } catch (err) {
        console.log("error", err);
        notification.error({
          message: "Error while sending purchase",
        });
      } finally {
        mailIsLoading.value = false;
      }
    };


    // Partner logic 
    const { partnerData, searchPartner, partnerFetching } = usePartnerSearch(
      formState.partnerId
    );
    // history Logic
    const showHistoryModal = ref(false);
    const toggelHistory = () => {
      showHistoryModal.value = !showHistoryModal.value;
    }

    const filterContact = (input, option) => {
      console.log("test", input);
      console.log("option", option);
      return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    /// Calculation 
    const totalNet = computed(() => totalCost.value + auxiliaryTotalCharge.value)
    const totalVat = computed(() => totalNet.value * (Number(formState.vat) / 100))
    const totalTtc = computed(() => totalNet.value + totalVat.value)

    return {
      partnerData,
      searchPartner,
      partnerFetching,
      formRef,
      rules,
      onSubmit,
      columns,
      formState,
      partnerApiLoading,
      partnerList,
      partnerApi,
      PurchaseApi,
      PurchaseId,
      productsData,
      PurchaseApiLoading,
      dateFormat,
      selectedProducts,
      editableData,
      editingKey: "",
      showAddProductModal,
      hideAddProductModal,
      showAddProduct,
      AddSelectedProduct,
      deleteFromPurchase,
      isPurchaseProductLoading,
      productPurchaseApi,
      productsIds,
      currentPurchaseNumber,
      params,
      DealIsPartialSale,
      purchaseProductLength,
      DealId,
      totalCost,
      fillAllProducts,
      formRefPayment,
      dynamicValidateForm,
      removePayment,
      addPayment,
      PaymentMethodApi,
      addModality,
      PurchasePaymentTermsApi,
      generatePdf,
      showEditProduct,
      showEditProductModal,
      EditProductId,
      updateProduct,
      hideEditProductModal,
      currentProduct,
      isPdfGenerated,
      pdfIsLoading,
      mailIsLoading,
      selectedPartner,
      canExportPdf,
      readOnlyMode,
      handleChangeReadMode,
      sendPurchaseViaMail,
      PaymentTermOptionApi,
      showHistoryModal,
      toggelHistory,
      auxiliaryChargeCmp,
      auxiliaryTotalCharge,
      totalNet,
      totalVat,
      totalTtc,
      filterContact,
      contactApi
    };
  },
};

export default Purchase;
</script>
<style lang="scss">
#app {
  .ant-upload {
    width: 100%;
  }

  .custom,
  .ant-calendar-picker-input.ant-input {
    height: 38px;
  }

  .button-container {
    align-self: center;

    .ant-btn {
      height: 38px;
      width: 100%;
    }
  }

  .editable-row-operations a {
    margin-right: 8px;
  }

  .table-footer {
    text-align: right;
    font-size: 15px;
    font-weight: bold;
  }

  .payment-buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 17px;
  }

  .payment-percent-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    padding-top: 14px;
  }
  .payments-row{
    margin-top: -40x;
  }
}
</style>
